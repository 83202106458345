<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <img
            src="logo_switaly_low.webp"
            alt="Logo"
          >

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            SWItaly-->
          <!--          </h2>-->
        </b-link>

        <b-card-title class="mb-1">
          Password dimenticata? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Inserisci la tua email e se sei hai un profilo su SWItaly ti invieremo una nuova password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="mariorossi@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Invia
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Torna al login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('users/resetPassword', { email: this.userEmail })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email inviata con successo',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'auth-login' })
            }).catch(error => {
              Object.values(error.response.data)
                .forEach(message => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'AlertTriangleIcon',
                      variant: 'warning',
                    },
                  })
                })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
